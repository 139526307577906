import React, { useContext, useState } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"
import { GATSBY_SKIP_FORMS } from "gatsby-env-variables"

import { AppContext } from "../../context/AppContext"
import ActionButtons from "../Elements/ActionButtons"
import Message from "../Elements/Message"
import InvalidFormMessage from "../Elements/InvalidFormMessage"
import ReferrerPersonalInformation from "./Refer/ReferrerPersonalInformation"
import FormInput from "../Elements/Form/FormInput"
import FormSelect from "../Elements/Form/FormSelect"
import ConsentAndAuthorization from "./Refer/ConsentAndAuthorization"

import { sendTeleconsultRequest } from "./services/sendTeleconsultRequest"
import {
  referIntakeValidationSchema,
  riskAssessmentScoreChoices,
  devReferInitialValues,
} from "./utils/formData"
import { intakeReferralEmailTemplate } from "./utils/intakeReferralEmailTemplate"
import { intakeReferralZendeskTemplate } from "./utils/zendeskTemplates"
import { getSignedInUser } from "../Auth/services/user"
import ReferredEmployeeInformation from "./ReferredEmployeeInformation"

import { GATSBY_CLIENT_EMAIL_DOMAIN } from "gatsby-env-variables"

const ReferForm = () => {
  const userData = getSignedInUser()?.userData

  const { state, dispatch } = useContext(AppContext)
  const { refer } = state
  const [isLoading, setLoading] = useState(false)
  const [hasErrorSubmitting, setHasErrorSubmitting] = useState(false)

  const handleSuccessSubmit = async () => {
    setLoading(false)
    await dispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
    await dispatch({ type: "RESET_DETAILS" })
    await dispatch({ type: "SET_SELF_REQUEST_COMPLETE" })
    navigate("/complete")
  }

  const handleErrorSubmit = () => {
    setLoading(false)
    setHasErrorSubmitting(true)
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    setHasErrorSubmitting(false)

    let employeeEmail =
      values?.employee?.email?.trim() + GATSBY_CLIENT_EMAIL_DOMAIN

    let newValues = {
      ...values,
      referrer: { ...values.referrer, ...referrer },
      employee: {
        ...values.employee,
        email: employeeEmail,
      },
    }

    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        refer: {
          ...state?.refer,
          ...newValues,
        },
      },
    })

    sendTeleconsultRequest({
      requestData: newValues,
      ticketTemplate: intakeReferralZendeskTemplate,
      emailTemplate: intakeReferralEmailTemplate,
      callback: handleSuccessSubmit,
      errorCallback: handleErrorSubmit,
      type: "referral",
    })
  }

  const referrer = {
    firstName: userData?.firstName,
    lastName: userData?.lastName,
    mobileNumber: userData?.mobileNumber,
    email: userData?.email,
    birthday: userData?.birthday,
  }

  const initialValues = { ...refer, referrer: { ...referrer } }

  return (
    <Formik
      initialValues={GATSBY_SKIP_FORMS ? devReferInitialValues : initialValues}
      validationSchema={referIntakeValidationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        setFieldValue,
        isValid,
        errors,
        submitCount,
      }) => {
        return (
          <Form key="intakeReferForm">
            <ReferrerPersonalInformation values={values} userData={userData} />
            <ReferredEmployeeInformation
              values={values}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              path="refer"
              errors={errors}
              submitCount={submitCount}
            />
            <FormInput
              label="Current Work Hours"
              name="employee.currentWorkHours"
              helper="Since we schedule teleconsults off-hours, this helps us know their availability"
              placeholder="Mon-Fri 6AM-3PM, Sat 2PM-11PM"
            />
            <hr className="mt-1 mb-3" />
            <FormInput
              label="Reason for Referral"
              name="reasonForReferral"
              type="text"
              placeholder="Type reason here..."
              isRequired
            />
            <FormSelect
              label="Risk Assessment Score"
              placeholder="Select from choices below..."
              options={riskAssessmentScoreChoices}
              name="riskAssessmentScore"
              value={values?.riskAssessmentScore}
            />
            {values?.riskAssessmentScore?.value === "Emergency" && (
              <div className="notification is-danger is-light">
                If urgent help is needed, please ask HR to trigger emergency
                procedures. Emergencies are typically defined as{" "}
                <b>cases with risk of harm to self or others</b>.
              </div>
            )}
            <div className="my-3" />
            <ConsentAndAuthorization values={values} />
            {!isValid && submitCount > 0 && (
              <InvalidFormMessage color="danger" name="missingRequiredFields" />
            )}
            {hasErrorSubmitting && (
              <Message color="warning">
                Your request failed to submit. Please check your network
                connection and try again.
              </Message>
            )}

            <ActionButtons
              submit={{
                loading: isLoading,
                label: "Request Teleconsult",
                disabled:
                  !values?.terms?.length || !values?.informedConsent?.length,
              }}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default ReferForm
